import React from 'react';
import { Button, Select, useClient } from '@digi-tim-19/components';
import { Icon, Modal, message, TreeSelect as AntdTreeSelect } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { downloadXLSX } from '../../utils/downloadXlsx';

interface Props {
  hideOpenCloseButton?: boolean;
  onClose?: Function;
}

const fragment = `
  name
  matricula
  region
  roleGroup
  title
  dateClick
  link
`;

export const ModalReportBannerActions = ({
  hideOpenCloseButton = false,
  onClose
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [dateClick, setdateClick] = React.useState<any>();
  const { loading, fetch } = useClient('ReportBannersActions', { fragment });

  const handleGenerateYears = () => {
    let options: any = [];

    for (let year = 2025; year <= moment().year(); year++) {
      options.push({
        label: `Ano/${year}`,
        value: `${year}-01-01`
      });
    }

    return options;
  };

  const handleSerializeResult = (result: any[]) => {
    return result.map((el: any) => {
      return {
        name: el?.name,
        matricula: el?.matricula,
        region: el?.region,
        roleGroup: el?.roleGroup,
        title: el?.title,
        dateClick: el?.dateClick || '',
        link: el?.link
      };
    });
  };

  const handleDownloadReport = async (dateClick: string) => {
    const { result } = await fetch({
      variables: { date: dateClick }
    });

    if (result && result?.length > 0) {
      const schema: any = {
        name: { label: 'Nome' },
        matricula: { label: 'Matrícula' },
        region: { label: 'Regional' },
        roleGroup: { label: 'Perfil' },
        title: { label: 'Título' },
        dateClick: { label: 'Data do clique' },
        link: { label: 'Link' }
      };

      downloadXLSX(
        `${moment().format('DD-MM-YYYY-HH-mm')}-relatório-cliques-banners`,
        schema,
        handleSerializeResult(result)
      );
      handleCloseModal();
    } else {
      message.info('Não há registros para download da planilha.');
    }
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      {!hideOpenCloseButton && (
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => setOpen(true)}
        >
          {!loading && <Icon type="file-excel" />}
          Relatório de Cliques
        </Button>
      )}
      <Modal
        title="Relatório de cliques"
        visible={!hideOpenCloseButton ? open : true}
        okText="DOWNLOAD"
        cancelText="FECHAR"
        onOk={async () => await handleDownloadReport(dateClick)}
        onCancel={() => {
          if (!loading) {
            handleCloseModal();
          }
        }}
        okButtonProps={{
          disabled: !dateClick,
          loading: loading
        }}
      >
        <Message>
          Este relatório pode demorar alguns minutos para finalizar o
          processamento. Por favor, permanecer na tela até a conclusão do
          download.
        </Message>
        <Select
          style={{ marginTop: '10px' }}
          placeholder="ANO"
          options={handleGenerateYears()}
          value={dateClick}
          onChange={(e) => {
            setdateClick(e);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

const Message = styled.div`
  width: 100%;
  font-size: 11pt;
  font-style: italic;
  margin: -10px 0px 10px 0px;
`;

const TreeSelect = styled(AntdTreeSelect)`
  color: ${(props) => props.theme.blue};
  width: 100%;
  margin-right: 15px;

  .ant-select-selection__placeholder,
  .ant-select-selection__rendered {
    text-transform: uppercase;
  }

  i {
    color: ${(props) => props.theme.warning};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
    &::after {
      content: '';
      margin: 0px 5px;
      width: 0;
      height: 0;
      border: solid ${(props) => props.theme.blue};
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform-origin: center center;
      transition: all 0.3s;
      transform: rotate(45deg);
    }
  }

  &.ant-select-open {
    text-transform: uppercase;
    i {
      &::after {
        transform: rotate(225deg);
        margin: -2px 5px;
      }
    }
  }
`;
