import React, { useState } from 'react';
import { message, Button } from 'antd';
import { useHistory } from 'react-router';
import { useDimensions } from '@digi-tim-19/utils/build';
import {
  Former,
  usePublico,
  LabelSelecionarPublico,
  SearchUser,
  useCurrentUser
} from '@digi-tim-19/components';
import {
  EnumMaterialKind,
  Material,
  EnumFileKind,
  EnumMaterialStatus
} from '../../autogenerated/client/types';
import { useClient } from '../../autogenerated/client/client';
import { useDocumentCategories } from '../../hooks/categories/useDocumentCategory';
import { routes } from '../../config/routes';
import { RegionalProfilesField } from '../../components/RegionalProfilesSelection/RegionalProfilesField';
import { Divider } from '../../components/Former';
import { validateSelectPublic } from '../../utils/validateSelectPublic';
import { DocsEditor } from 'components/DocsEditor/DocsEditor';
import { FormWrapper, WrapperLabel } from './styles';
import { userIsResidential } from 'utils/validations';
import moment, { Moment } from 'moment';

const mimetypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];

const configPage = {
  kind: EnumMaterialKind.Document,
  kindThumb: EnumFileKind.CardThumb,
  kindFile: EnumFileKind.AnyDoc,
  routeList: routes.documentosGerenciar.mount(),
  messageSuccess: 'Documento cadastrado com sucesso',
  messageError: 'Documento não cadastrado'
};

export const Form = (props: TFormMaterialEditProps) => {
  const user = useCurrentUser();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();
  const categoryOptions = useDocumentCategories();
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const createMaterial = useClient('MaterialCreateOne');
  const [publicSelected, setPublicSelected] = useState(false);
  const [isHTML, setIsHTML] = useState(!!props.initialValues?.html || false);
  const [files, setFiles] = useState(
    props?.initialValues?.files?.filter(
      (el) => el?.kind === EnumFileKind.AnyDoc && el?._id
    ) || []
  );
  const isUpdate = !!props.initialValues;
  const isResidential = userIsResidential(user?.result);

  const initialValues = React.useMemo(() => {
    const documents = props.initialValues?.files
      ?.map((file) => file?.kind === EnumFileKind.AnyDoc && file._id)
      .filter((el) => el);

    const validityStart =
      props.initialValues?.validity?.start || !isResidential
        ? moment().startOf('day').toISOString()
        : undefined;
    const validityEnd =
      props.initialValues?.validity?.end || !isResidential
        ? moment(validityStart).add(18, 'months').endOf('day').toISOString()
        : undefined;
    const validity = [validityStart, validityEnd];

    return {
      ...props.initialValues,
      documents,
      validity,
      category: (props.initialValues?.categories || []).find(
        (el) => el && el._id
      )?._id,
      notifyEmailsBeforeExpire:
        props.initialValues?.validity?.notifyEmailsBeforeExpire
    };
  }, [props?.initialValues]);

  return (
    <FormWrapper>
      <Former
        initialValues={initialValues}
        config={({ form }) => {
          const showDownloadOption = files.find(
            (el: any) =>
              (el?.extension &&
                ['pdf', 'jpg', 'jpeg', 'png', 'gif'].includes(el?.extension)) ||
              (el?.type && mimetypes.includes(el?.type))
          );
          const hasExpiration = form.getFieldState('hasExpiration')?.value
            ? true
            : false;

          return {
            fields: [
              {
                inline: !isMobile,
                list: [
                  isResidential && {
                    name: 'hasExpiration',
                    label: 'Tem data de expiração?',
                    type: 'select',
                    required: true,
                    maxWidth: '100%',
                    options: [
                      {
                        label: 'Não',
                        value: false
                      },
                      {
                        label: 'Sim',
                        value: true
                      }
                    ]
                  },
                  Boolean(hasExpiration || !isResidential) && {
                    name: 'validity',
                    label: 'Data de Vigência',
                    type: 'rangePicker',
                    required: true,
                    extraProps: {
                      format: 'DD/MM/YYYY HH:mm',
                      showTime: { format: 'HH:mm' },
                      disabledDate: (current: Moment) => {
                        if (!isResidential) {
                          const start = moment().startOf('day');
                          const end = moment(start)
                            .add(18, 'months')
                            .endOf('day');
                          return (
                            moment(current).startOf('day').isBefore(start) ||
                            moment(current).endOf('day').isAfter(end)
                          );
                        }
                        return false;
                      }
                    }
                  },
                  {
                    name: 'category',
                    label: 'CAMINHO',
                    type: 'treeSelect',
                    treeOptions: categoryOptions,
                    required: true
                  }
                ]
              },
              {
                name: 'documents',
                label: '',
                type: 'upload',
                extraProps: {
                  kind: configPage.kindFile,
                  CTA: 'IMPORTAR ARQUIVO',
                  multiple: true,
                  listType: 'text',
                  returnId: (file: any, recordId: any) => {
                    setFiles((prev) => [
                      ...prev,
                      {
                        ...file,
                        type: file?.type,
                        name: file?.name,
                        id: recordId
                      }
                    ]);
                  },
                  onRemove: (file: any) => {
                    setFiles((prev) => {
                      return [
                        ...prev.filter((el: any) => {
                          if (el?.id === file?.id) {
                            return false;
                          }
                          return true;
                        })
                      ];
                    });
                  }
                }
              },
              {
                name: 'link',
                label: 'Possui link?'
              },
              {
                custom: <Divider />
              },
              {
                name: 'title',
                label: 'TÍTULO (até 110 caracteres)',
                required: true,
                validate: (value: any) => {
                  const format = /[#%&;]/;
                  if (format.test(value))
                    return 'Os caracteres #%&; não são permitidos';

                  if (value) {
                    return !(value.length > 110)
                      ? undefined
                      : 'Máximo de 110 caracteres';
                  }

                  return undefined;
                }
              },
              {
                className: 'content-buttons',
                inline: true,
                list: [
                  {
                    custom: (
                      <Button type="primary" onClick={() => setIsHTML(!isHTML)}>
                        {isHTML ? 'Fechar' : 'Usar'} HTML
                      </Button>
                    )
                  },
                  {
                    name: 'editor',
                    label: 'editor',
                    custom: (
                      <DocsEditor
                        fieldConfig={{
                          initialValue: props.initialValues?.editor
                        }}
                      />
                    )
                  }
                ]
              },
              isHTML && {
                name: 'html',
                label: isMobile
                  ? 'Uso exclusivo para documentos HTML'
                  : 'USO EXCLUSIVO PARA DOCUMENTOS EM HTML',
                type: 'textarea',
                extraProps: {
                  rows: 5
                }
              },
              {
                custom: <Divider />
              },
              {
                name: 'tags',
                label: 'Palavra-chave',
                type: 'tags'
              },
              Boolean(hasExpiration || !isResidential) && {
                required: !isResidential,
                name: 'notifyEmailsBeforeExpire',
                label:
                  'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
                placeholder: isMobile
                  ? 'Notificar antes de expirar'
                  : 'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
                extraProps: {
                  returnProp: 'email'
                },
                component: SearchUser
              },
              {
                custom: <Divider />
              },
              {
                className: 'Footer',
                list: [
                  {
                    custom: (
                      <RegionalProfilesField
                        fieldConfig={{
                          initialValue:
                            props.initialValues?.availableAtRegionalProfiles
                        }}
                        defaultChecked={
                          isUpdate
                            ? undefined
                            : [
                                'THQ#residencial:adm_trade_residencial',
                                'THQ#corporate:adm_trade_corporate'
                              ]
                        }
                      />
                    )
                  },
                  publicSelected && {
                    custom: (
                      <WrapperLabel>
                        <LabelSelecionarPublico />
                      </WrapperLabel>
                    )
                  }
                ]
              },
              {
                name: 'enableSocialInteraction',
                label: 'Permitir comentários e curtidas',
                type: 'switch'
              },
              {
                name: 'isDraft',
                label: 'Salvar como rascunho',
                type: 'switch'
              },
              Boolean(showDownloadOption) && {
                className: 'switch-download',
                name: 'download',
                label: 'Permitir download',
                type: 'switch',
                defaultValue:
                  typeof props?.initialValues?.download === 'undefined' ||
                  props?.initialValues?.download === null
                    ? true
                    : props?.initialValues?.download
              }
            ],
            submitButton: {
              label: isUpdate ? 'Atualizar' : 'Salvar',
              onClick: (formerRef: any) => {
                setPublicSelected(validateSelectPublic(formerRef));
              }
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(configPage.routeList)
            }
          };
        }}
        onSubmit={async ({ data }: any) => {
          const {
            documents = [],
            html,
            editor,
            availableAtRegionalProfiles,
            download
          } = data;
          const fileIds = [...documents].filter(Boolean);

          const hasDocuments = !!documents?.length;
          const hasEditorHTML = !!editor?.length;

          if (!html && !hasEditorHTML && !hasDocuments) {
            return message.error('Selecione um arquivo ou HTML');
          }

          if (!availableAtRegionalProfiles) {
            return setPublicSelected(true);
          }

          const record: any = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            fileIds: fileIds,
            title: data.title,
            link: data.link,
            html: data.html === undefined ? '' : data.html,
            categories: [data.category],
            tags: data.tags,
            kind: configPage.kind,
            enableSocialInteraction: data.enableSocialInteraction,
            editor: data.editor,
            editorFiles: data.editorFiles,
            isDraft: data.isDraft,
            status: data.isDraft
              ? EnumMaterialStatus.Editing
              : EnumMaterialStatus.Approved,
            download
          };

          if (data?.hasExpiration || !isResidential) {
            record.validity = {
              start: data.validity[0],
              end: data.validity[1],
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire
            };
          }

          if (isUpdate && data._id) {
            await MaterialUpdateOne.fetch({
              variables: { record, filter: { _id: data._id } }
            }).then((ctx) => {
              if (!ctx.errors) {
                history.push(configPage.routeList);
              } else {
                console.error('Erro ao atualizar', ctx.errors);
              }
            });
          } else {
            await createMaterial
              .fetch({ variables: { record } })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  console.error('errors', ctx.errors);
                  message.error(configPage.messageError);
                }
              });
          }
        }}
      />
    </FormWrapper>
  );
};

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
};
