import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { message } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  Select,
  TypeCard,
  TypeCardDescription
} from '@digi-tim-19/components';
import { formatDate, useDimensions } from '@digi-tim-19/utils/build';

import { routes } from '../../../../config/routes';
import { useClient } from '../../../../autogenerated/client/client';
import { PageTemplate } from '../../../../components/Layout/PageTemplate';
import {
  CleanButton,
  FilterContainer
} from '../../../../components/PageFilter';
import {
  ValidityEnum,
  SortFindManyBannerInput,
  EnumBannerStatus
} from '../../../../autogenerated/client/types';

import { columns } from './columns';
import { PageWrapper, Button } from './styleListar';
import { useInMemoPagination } from 'hooks/useInMemoPagination';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ModalReportBannerActions } from 'components/ReportBanners/ModalReportBannerActions';

function getPosition(value: string): TypeCard {
  switch (value) {
    case '1':
      return TypeCard.LARGE;
    case '2':
      return TypeCard.MEDIUM1;
    case '3':
      return TypeCard.MEDIUM2;
    case '4':
      return TypeCard.SMALL;
    case '5':
      return TypeCard.BANNER;
    case '6':
      return TypeCard.SMALL2;
    case '7':
      return TypeCard.BANNER_FOOTER;
    default:
      return TypeCard.LARGE;
  }
}

const configPage = {
  pageName: 'Banners e cards',
  icon: 'file-image',
  description: 'Gerenciamento de banners e cards da home.',
  messageDeletedSuccess: 'Banner cancelado com sucesso',
  textButtonNew: 'Cadastrar banners e cards',
  routeNewRegister: routes.bannersCadastrar.mount(),
  routeEdit: (id: string) => routes.bannersEditar.mount(id),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Banners e cards', link: routes.bannersListar.mount() }
  ]
};

export const BannerListar: React.FC<TBannerListarProps> = () => {
  const { isMobile } = useDimensions();
  const history = useHistory();
  const getBanners = useClient('BannerFindMany');
  const bannerUpdate = useClient('BannerUpdateOne');
  const [updateTable, setUpdateTable] = useState('');
  const [resultBanners, setResultBanners] = useState<any>([]);
  const [sort, setSort] = useState(SortFindManyBannerInput.IdDesc);
  const [validityEnum, setValidityEnum] = useState(ValidityEnum.Any);
  const [filterStatus, setFilterStatus] = useState<EnumBannerStatus>();
  const { dataToRender, pagination } = useInMemoPagination({
    data: resultBanners || [],
    perPage: 10
  });

  useEffect(() => {
    getBanners
      .fetch({
        variables: {
          limit: 10000,
          filter: {
            validityEnum,
            status: filterStatus
          },
          sort
        },
        appendToFragment: ` images { link fileId display signedUrl }  validity { start end } `
      })
      .then((ctx) => {
        if (ctx.result) {
          setResultBanners(ctx.result);
        }
      });
  }, [sort, validityEnum, filterStatus, updateTable]);

  const resetFilters = () => {
    setValidityEnum(ValidityEnum.Any);
    setSort(SortFindManyBannerInput.IdDesc);
    setFilterStatus(undefined);
  };

  const showBanner = (banner: any) => {
    if (!isMobile) {
      const url = banner.images.filter((e: any) => e.display !== 'mobile');
      window.open(url[0].signedUrl);
    } else {
      const url = banner.images.filter((e: any) => e.display === 'mobile');
      window.open(url[0].signedUrl);
    }
  };

  const data = dataToRender.map((banner: any) => {
    const validity = `${formatDate(banner?.validity.start, 'DD/MM/YYYY')}
     até ${formatDate(banner?.validity.end, 'DD/MM/YYYY')}`;

    const positionBanner = getPosition(banner?.position);

    const isAfter = banner?.validity
      ? moment(banner?.validity.end).isAfter(new Date())
      : true;
    const makeStatusName = () => {
      if (banner?.status === EnumBannerStatus.Cancelled) return 'Cancelado';
      if (!isAfter) return 'Inativo';
      return 'Ativo';
    };

    return {
      key: banner?._id,
      title: banner?.title,
      position: TypeCardDescription[positionBanner],
      validity: validity,
      sort: banner?.sort,
      status: makeStatusName(),
      onEdit: () => history.push(configPage.routeEdit(banner?._id)),
      onRead: () => showBanner(banner),
      onDelete: () => {
        const record = { status: EnumBannerStatus.Cancelled };

        bannerUpdate
          .fetch({
            variables: { record, filter: { _id: banner?._id } },
            afterMutate: /^Banner/
          })
          .then((ctx) => {
            message.success(configPage.messageDeletedSuccess);
            setUpdateTable(ctx.result?.recordId);
          });
      }
    };
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <PageWrapper>
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <FilterContainer>
          <Button to={configPage.routeNewRegister}>
            {configPage.textButtonNew}
          </Button>
          <ModalReportBannerActions />
          <Select
            placeholder="Ordenar por"
            options={orders}
            onChange={(e: any) => setSort(e)}
            value={sort}
          />
          <Select
            placeholder="Status"
            options={statusList}
            value={
              validityEnum === ValidityEnum.Expired
                ? validityEnum
                : filterStatus
            }
            onChange={(e) => {
              switch (e) {
                case EnumBannerStatus.Published:
                  setFilterStatus(e);
                  setValidityEnum(ValidityEnum.Current);
                  break;
                case EnumBannerStatus.Cancelled:
                  setFilterStatus(e);
                  setValidityEnum(ValidityEnum.Any);
                  break;
                default:
                  setFilterStatus(undefined);
                  setValidityEnum(e);
              }
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>

        <TablePagination
          loading={getBanners.loading}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          emptyMessage={'Não há banners ou cards para exibir'}
        />
      </>
    </PageTemplate>
  );
};

export type TBannerListarProps = {};

const orders = [
  { label: 'MAIS ANTIGO', value: SortFindManyBannerInput.IdAsc },
  { label: 'MAIS RECENTE', value: SortFindManyBannerInput.IdDesc }
];

const statusList = [
  { label: 'ATIVO', value: EnumBannerStatus.Published },
  { label: 'INATIVO', value: ValidityEnum.Expired },
  { label: 'CANCELADO', value: EnumBannerStatus.Cancelled }
];
