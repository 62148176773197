import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDimensions } from '@digi-tim-19/utils';
import { message, Button as AntdButton } from 'antd';
import {
  Former,
  usePublico,
  TypeCard,
  LabelSelecionarPublico,
  SearchUser,
  Button
} from '@digi-tim-19/components';
import {
  EnumFileKind,
  EnumBannerImagesDisplay,
  Banner
} from '../../../autogenerated/client/types';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { RegionalProfilesField } from '../../../components/RegionalProfilesSelection/RegionalProfilesField';
import { Container, Divider } from './styleForm';
import { Preview } from './Preview';

const WrapperLabel = styled.div`
  margin-top: -30px;
  display: flex;
  flex: auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const configPage = {
  routeList: routes.bannersListar.mount(),
  messageSuccess: 'Banner cadastrado com sucesso',
  messageError: 'Banner não cadastrado',
  kindFile: EnumFileKind.BannerImage
};

export const Form = (props: TFormBannerEditProps) => {
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } =
    usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();
  const createBanner = useClient('BannerCreateOne');
  const updateBanner = useClient('BannerUpdateOne');
  const [publicSelected, setPublicSelected] = useState(false);
  const [position, setPosition] = useState('');
  const [CTAImageUpload, setCTAImageUpload] = useState('');
  const [CTAImageUploadMobile, setCTAImageUploadMobile] = useState('');
  const [data, setData] = useState(props?.initialValues || {});

  const isUpdate = !!props.initialValues;

  const imageDesktop = data?.images?.find(
    (item) =>
      item?.display === EnumBannerImagesDisplay.Desktop ||
      EnumBannerImagesDisplay.Stories
  );

  const imageMobile = data?.images?.find(
    (item) => item?.display === EnumBannerImagesDisplay.Mobile
  );

  const imageUrlDesktop = imageDesktop?.signedUrl;
  const imageUrlMobile = imageMobile?.signedUrl;

  const initialValues = useMemo(() => {
    return {
      position: data?.position,
      imageDesktop: imageDesktop?.fileId,
      imageMobile: imageMobile?.fileId,
      notifyEmailsBeforeExpire: data?.validity?.notifyEmailsBeforeExpire,
      ...data
    };
  }, [data]);

  function getLabelUploadButton(position: string) {
    if (position === TypeCard.SHORTCUTS.toString()) return 'Atalhos';
    if (position === TypeCard.STORIES.toString()) return 'Stories';
    return 'Desktop';
  }

  function changePosition(value: string) {
    setPosition(value);

    switch (parseInt(value)) {
      case TypeCard.BANNER:
        setCTAImageUpload('1150 (L) X 500 (A)');
        setCTAImageUploadMobile('425 (L) X 320 (A)');
        break;
      case TypeCard.SHORTCUTS:
        setCTAImageUpload('350 (L) X 280 (A)');
        setCTAImageUploadMobile('350 (L) X 280 (A)');
        break;
      case TypeCard.LARGE:
        setCTAImageUpload('410 (L) X 320 (A)');
        setCTAImageUploadMobile('280 (L) X 280 (A)');
        break;
      case TypeCard.STORIES:
      case TypeCard.MEDIUM1:
        setCTAImageUpload('206 (L) X 320 (A)');
        setCTAImageUploadMobile('280 (L) X 320 (A)');
        break;
      case TypeCard.MEDIUM2:
        setCTAImageUpload('206 (L) X 320 (A)');
        setCTAImageUploadMobile('206 (L) X 320 (A)');
        break;
      case TypeCard.SMALL:
        setCTAImageUpload('242 (L) X 158 (A)');
        setCTAImageUploadMobile('280 (L) X 120 (A)');
        break;
      case TypeCard.SMALL2:
        setCTAImageUpload('242 (L) X 158 (A)');
        setCTAImageUploadMobile('280 (L) X 120 (A)');
        break;
      case TypeCard.BANNER_FOOTER:
        setCTAImageUpload('1100 (L) X 224 (A)');
        setCTAImageUploadMobile('280 (L) X 224 (A)');
        break;
      default:
        setCTAImageUpload('1250 (L) X 754 (A)');
        setCTAImageUploadMobile('736 (L) X 310 (A)');
        break;
    }
  }

  if (initialValues?.position && CTAImageUpload === '')
    changePosition(initialValues?.position);

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={{
          fields: [
            {
              inline: !isMobile,
              className: 'FormHeader',
              list: [
                {
                  name: 'position',
                  label: 'Área da home',
                  required: true,
                  disabled: isUpdate,
                  afterChange: changePosition,
                  maxWidth: '250px',
                  options: [
                    {
                      value: TypeCard.BANNER.toString(),
                      label: 'Banner'
                    },
                    {
                      value: TypeCard.SHORTCUTS.toString(),
                      label: 'Atalhos'
                    },
                    {
                      value: TypeCard.STORIES.toString(),
                      label: 'Stories'
                    },
                    {
                      value: TypeCard.LARGE.toString(),
                      label: 'Card Grande'
                    },
                    {
                      value: TypeCard.MEDIUM1.toString(),
                      label: 'Card Medio 01'
                    },
                    {
                      value: TypeCard.MEDIUM2.toString(),
                      label: 'Card Medio 02'
                    },
                    {
                      value: TypeCard.SMALL.toString(),
                      label: 'Card pequeno 01'
                    },
                    {
                      value: TypeCard.SMALL2.toString(),
                      label: 'Card pequeno 02'
                    },
                    {
                      value: TypeCard.BANNER_FOOTER.toString(),
                      label: 'Banner rodapé'
                    }
                  ]
                },
                position !== '' && {
                  custom: <Preview position={position} />
                },
                position !== '' && {
                  name: 'imageDesktop',
                  label: getLabelUploadButton(position),
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindFile,
                    CTA: `${CTAImageUpload}`,
                    hasPreview: true,
                    imageUrl: imageUrlDesktop,
                    widthPreview: '100px',
                    allowedExtensions: ['image/png', 'image/jpeg']
                  }
                },
                position !== '' &&
                  position !== TypeCard.STORIES.toString() &&
                  position !== TypeCard.SHORTCUTS.toString() && {
                    name: 'imageMobile',
                    label: 'Mobile',
                    type: 'upload',
                    required: true,
                    extraProps: {
                      kind: configPage.kindFile,
                      CTA: `${CTAImageUploadMobile}`,
                      hasPreview: true,
                      imageUrl: imageUrlMobile,
                      widthPreview: '100px',
                      allowedExtensions: ['image/png', 'image/jpeg']
                    }
                  }
              ]
            },
            {
              custom: <Divider />
            },
            {
              inline: !isMobile,
              list: [
                {
                  name: 'title',
                  label: 'Título',
                  required: true,
                  validate: (value: any) => {
                    const format = /[#%;]/;
                    if (format.test(value))
                      return 'Os caracteres #%&; não são permitidos';

                    if (value) {
                      return !(value.length > 110)
                        ? undefined
                        : 'Máximo de 110 caracteres';
                    }

                    return undefined;
                  }
                },
                {
                  name: 'sort',
                  label: 'Ordem',
                  type: 'number',
                  required: true,
                  min: 0,
                  max: 100,
                  validate: (value: any) => {
                    if (value) {
                      if (value < 0) {
                        return 'Número precisa ser maior ou igual a zero';
                      } else if (value > 100) {
                        return 'Order precisa ser no máximo máximo 100';
                      }
                    }
                    return undefined;
                  }
                }
              ]
            },
            {
              custom: <Divider />
            },
            {
              inline: true,
              name: 'description',
              label: 'Descrição',
              validate: (value: any) => {
                const format = /[#%;]/;
                if (format.test(value))
                  return 'Os caracteres #%&; não são permitidos';

                if (value) {
                  return !(value.length > 110)
                    ? undefined
                    : 'Máximo de 110 caracteres';
                }

                return undefined;
              }
            },
            {
              inline: !isMobile,
              list: [
                {
                  name: 'link',
                  label: 'Link',
                  validate: (value: any) => {
                    if (value) {
                      return !(value.length > 1200)
                        ? undefined
                        : 'Máximo de 1200 caracteres';
                    }
                    return undefined;
                  }
                }
              ]
            },
            {
              custom: <Divider />
            },
            {
              inline: !isMobile,
              list: [
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    format: 'DD/MM/YYYY',
                    showTime: { format: 'HH:mm' }
                  }
                }
              ]
            },
            {
              name: 'notifyEmailsBeforeExpire',
              label:
                'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
              placeholder: isMobile
                ? 'Notificar antes de expirar'
                : 'Escolha um ou mais usuários que devem ser avisados antes do término da vigência deste documento',
              extraProps: {
                returnProp: 'email'
              },
              component: SearchUser
            },
            {
              custom: <Divider />
            },
            {
              className: 'Footer',
              inline: true,
              list: [
                {
                  custom: (
                    <RegionalProfilesField
                      fieldConfig={{
                        initialValue:
                          props.initialValues?.availableAtRegionalProfiles
                      }}
                    />
                  )
                }
              ]
            },
            publicSelected && {
              custom: (
                <WrapperLabel>
                  <LabelSelecionarPublico />
                </WrapperLabel>
              )
            }
          ],
          customRenderFooter: (renderProps: any) => {
            const { form, invalid } = renderProps;
            return (
              <Footer>
                <AntdButton onClick={() => history.push(configPage.routeList)}>
                  CANCELAR
                </AntdButton>
                <Button
                  loading={updateBanner.loading || createBanner.loading}
                  disabled={
                    invalid || updateBanner.loading || createBanner.loading
                  }
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {isUpdate ? 'Atualizar' : 'Cadastrar'}
                </Button>
              </Footer>
            );
          }
        }}
        onSubmit={async ({ data }: any) => {
          const start = data.validity[0] || data.validity.start;
          const end = data.validity[1] || data.validity.end;

          const record = {
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            availableAtRegionalProfiles: data.availableAtRegionalProfiles,
            title: data.title,
            description: data?.description,
            position: data.position,
            sort: typeof data?.sort === 'undefined' ? 0 : data.sort,
            validity: {
              start,
              end,
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire
            },
            link: data.link,
            images: [
              {
                fileId: data?.imageDesktop,
                display: EnumBannerImagesDisplay.Desktop
              },
              {
                fileId: data?.imageMobile,
                display: EnumBannerImagesDisplay.Mobile
              }
            ]
          };

          if (position === String(TypeCard.STORIES)) {
            record.images = [
              {
                fileId: data?.imageDesktop,
                display: EnumBannerImagesDisplay.Stories
              }
            ];
          }

          if (position === String(TypeCard.SHORTCUTS)) {
            record.images = [
              {
                fileId: data?.imageDesktop,
                display: EnumBannerImagesDisplay.Shortcuts
              }
            ];
          }

          if (!data.availableAtRegionalProfiles) {
            setData({ ...record });
            return setPublicSelected(true);
          }

          if (isUpdate) {
            await updateBanner
              .fetch({
                variables: {
                  record,
                  filter: { _id: props?.initialValues?._id }
                },
                afterMutate: /^Banner/
              })
              .then((ctx) => {
                if (!ctx.errors) {
                  history.push(configPage.routeList);
                } else {
                  message.error(
                    ctx.errors && ctx.errors.length > 0
                      ? ctx.errors[0]
                      : 'Não foi possível salvar as alterações do banner'
                  );
                  setData({ ...record });
                }
              });
          } else {
            await createBanner.fetch({ variables: { record } }).then((ctx) => {
              if (ctx?.result) {
                message.success(configPage.messageSuccess);
                history.push(configPage.routeList);
              } else {
                message.error(
                  ctx.errors && ctx.errors.length > 0
                    ? ctx.errors[0]
                    : 'Não foi possível cadastrar o banner'
                );
                setData({ ...record });
              }
            });
          }
        }}
      />
    </Container>
  );
};

export type TFormBannerEditProps = {
  initialValues?: Partial<Banner>;
};
