import React, { useState, useEffect } from 'react';
import {
  LoadingIndicator,
  SuperBanner,
  TypeCard
} from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import { useHistory } from 'react-router';
import { EnumBannerImagesDisplay } from '@digi-tim-19/components/build/main/DataProviders/types';
import {
  SortFindManyBannerInput,
  EnumBannerStatus,
  ValidityEnum
} from '../../autogenerated/client/types';
import { RegisterBannerClickProps } from './Home';

interface Props {
  onRegisterBannerClick: (data: RegisterBannerClickProps) => void;
}

export const Banner = (props: Props) => {
  const history = useHistory();
  const [images, setImages] = useState<any[]>([]);
  const getBanner = useClient('BannerFindMany', {
    fetchOnMount: true,
    appendToFragment: ` images { link fileId display signedUrl }  validity { start end } `,
    variables: {
      limit: 100,
      sort: SortFindManyBannerInput.SortAsc,
      filter: {
        validityEnum: ValidityEnum.Current,
        status: EnumBannerStatus.Published
      }
    }
  });

  useEffect(() => {
    if (getBanner.result) {
      const imagesBanner = getBanner.result.filter(
        (item) => item?.position && item.position === TypeCard.BANNER.toString()
      );

      const resultImages = imagesBanner?.map((banner: any) => {
        const imageDesktop = banner?.images.find(
          (item: any) => item.display === EnumBannerImagesDisplay.Desktop
        );
        const imageMobile = banner?.images.find(
          (item: any) => item.display === EnumBannerImagesDisplay.Mobile
        );

        return {
          urlImgDesktop: imageDesktop?.signedUrl,
          urlImgMobile: imageMobile?.signedUrl,
          backgroundColor: '#fff',
          title: '',
          detailTitle: '',
          titleFinal: '',
          description: '',
          textButton: banner?.textButton,
          functionButton: () => {
            if (Boolean(banner?.link)) {
              props.onRegisterBannerClick({
                bannerId: banner?._id,
                link: banner?.link!
              });
              if (
                banner.link.indexOf('https://') === 0 ||
                banner.link.indexOf('http://') === 0
              )
                window.open(banner?.link, '_blank');
              else history.push(banner?.link);
            }
          }
        };
      });

      setImages(resultImages || []);
    }
  }, [getBanner.result]);

  if (getBanner.loading) {
    return <LoadingIndicator />;
  }

  if (images.length === 0) {
    return null;
  }

  return (
    <SuperBanner plataform="corp" className="supper-banner" items={images} />
  );
};
