import React from 'react';
import styled from 'styled-components';
import {
  EnumBannerImagesDisplay,
  EnumBannerStatus,
  SortFindManyBannerInput,
  ValidityEnum
} from 'autogenerated/client/types';
import { useClient } from 'autogenerated/client/client';
import { Button, TypeCard } from '@digi-tim-19/components';
import { RegisterBannerClickProps } from './Home';
import { colors } from '@digi-tim-19/theme';
import { Divider } from 'antd';

interface Props {
  onRegisterBannerClick: (data: RegisterBannerClickProps) => void;
}

const ShortcutsContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 5px auto;
`;

const ShortcutsCardsContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media screen and (max-width: 860px) {
    flex-flow: wrap;
    width: 85%;
    margin: auto;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 460px) {
    width: 70%;
    margin: auto;
  }
`;

const ShortcutsCard = styled.div<{ hasLink?: boolean }>`
  width: 25%;
  height: 230px;
  border-radius: 5px;
  background-color: ${colors.blue};

  @media screen and (max-width: 860px) {
    flex: 1 45%;
  }

  @media screen and (max-width: 460px) {
    flex: 1 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 130px;
  padding: 15px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: ${colors.blue};
`;

const Title = styled.div`
  width: 100%;
  font-size: 14pt;
  font-weight: bold;
`;

const Description = styled.div`
  width: 100%;
  font-size: 10pt;
`;

const Content = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ShortcutsTitle = styled.div`
  width: 100%;
  padding-top: 15px;
  color: ${colors.blue};
  font-size: 15pt;
  font-weight: bold;
  font-family: TimRegular;
`;

export const Shortcuts = (props: Props) => {
  const getBanner = useClient('BannerFindMany', {
    fetchOnMount: true,
    appendToFragment: `
      images {
        link
        fileId
        display
        signedUrl
      }
      validity {
        start
        end
      }
    `,
    variables: {
      limit: 4,
      sort: SortFindManyBannerInput.SortAsc,
      filter: {
        validityEnum: ValidityEnum.Current,
        status: EnumBannerStatus.Published,
        position: TypeCard.SHORTCUTS.toString()
      }
    }
  });

  function getUrlToRedirect(url: string) {
    return `https://${url.replace('https://', '').replace('http://', '')}`;
  }

  if (!getBanner?.result?.length || getBanner?.loading) return null;

  return (
    <ShortcutsContainer>
      <ShortcutsTitle>Acesso rápido</ShortcutsTitle>
      <Divider style={{ margin: '12px 0' }} />
      <ShortcutsCardsContainer>
        {getBanner?.result?.map((shortcut) => {
          const signedUrl = shortcut?.images?.find(
            (item: any) => item.display === EnumBannerImagesDisplay.Shortcuts
          )?.signedUrl;
          return (
            <ShortcutsCard>
              <Header>
                <Title>{shortcut?.title || ''}</Title>
                <Description>{shortcut?.description || ''}</Description>
              </Header>
              <Content>
                <img
                  src={signedUrl || ''}
                  alt={shortcut?.title || 'Imagem de atalho'}
                />
                <Button
                  onClick={() => {
                    if (Boolean(shortcut?.link)) {
                      props.onRegisterBannerClick({
                        bannerId: shortcut?._id,
                        link: shortcut?.link!
                      });
                      window.open(getUrlToRedirect(shortcut?.link!), '_blank');
                    }
                  }}
                >
                  Acessar
                </Button>
              </Content>
            </ShortcutsCard>
          );
        })}
      </ShortcutsCardsContainer>
    </ShortcutsContainer>
  );
};
