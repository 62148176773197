import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
  Cards,
  TypeCard,
  CardConfig,
  LoadingIndicator
} from '@digi-tim-19/components';
import {
  EnumBannerImagesDisplay,
  ValidityEnum
} from '@digi-tim-19/components/build/main/DataProviders/types';
import { useDimensions } from '@digi-tim-19/utils/build';

import { useClient } from '../../autogenerated/client/client';
import {
  Banner,
  EnumBannerStatus,
  Maybe,
  SortFindManyBannerInput
} from 'autogenerated/client/types';
import { CarouselStories } from 'components/HomeStories/CarouselStories';
import { RegisterBannerClickProps } from './Home';

interface Props {
  onRegisterBannerClick: (data: RegisterBannerClickProps) => void;
}

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: ${(props) => props.theme.white};
  border-radius: 20px;
  padding-top: 20px;
  width: 320px;
  margin-bottom: 20px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    width: 590px;
    border-radius: 20px;
    padding: 20px;
  }
  @media screen and (min-width: 1024px) {
    width: 840px;
    /* margin-top: -150px; */
  }
  @media screen and (min-width: 1250px) {
    width: 940px;
    max-width: 1150px;
  }
  @media screen and (min-width: 1280px) {
    width: 1150px;
    max-width: 1150px;
  }
  @media screen and (min-width: 1366px) {
    width: 1150px;
    max-width: 1150px;
  }
  /* @media screen and (min-width: 1440px) {
    width: 1115px;
    max-width: 1150px;
  } */
  @media screen and (min-width: 1920px) {
    width: 1550px;
    max-width: 1150px;
  }

  .banner-footer {
    border-radius: 17px;
    height: 280px;
    width: 315px;

    @media screen and (min-width: 768px) {
      width: 100%;
    }
  }
`;

const MiniCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    width: auto;
    justify-content: space-between;
  }
`;

function buildObjectCard(
  banner: Maybe<Banner>,
  history: any,
  onRegisterBannerClick: Function
): CardConfig {
  const imageDesktop = banner?.images?.find(
    (item: any) =>
      item.display === EnumBannerImagesDisplay.Desktop ||
      EnumBannerImagesDisplay.Stories
  );
  const imageMobile = banner?.images?.find(
    (item: any) => item.display === EnumBannerImagesDisplay.Mobile
  );
  const hasLinkCard =
    banner?.link !== undefined && banner?.link !== '' && banner?.link !== null
      ? true
      : false;

  const result = {
    title:
      banner?.title && banner?.position === String(TypeCard.STORIES)
        ? banner?.title
        : '',
    description: '',
    imgDesk: imageDesktop?.signedUrl,
    imgMobile: imageMobile?.signedUrl,
    textButton: banner?.textButton,
    colorDescription: '#00f',
    hasLinkCard: hasLinkCard,
    functionButton: () => {
      if (Boolean(banner?.link)) {
        onRegisterBannerClick({ bannerId: banner?._id, link: banner?.link! });
        if (
          banner?.link?.indexOf('https://') === 0 ||
          banner?.link?.indexOf('http://') === 0
        )
          window.open(banner?.link, '_blank');
        else history.push(banner?.link);
      }
    }
  };

  return result as CardConfig;
}

function filterByPosition(
  data: Maybe<Banner>[],
  position: string | TypeCard,
  history: any,
  onRegisterBannerClick: Function
) {
  return (
    data
      ?.filter((item) => item?.position && item?.position === position)
      .map((item) => buildObjectCard(item, history, onRegisterBannerClick)) ||
    []
  );
}

const appendToFragment = `
  images {
    title
    link
    fileId
    display
    signedUrl
  }
  validity {
    start end
  }
`;

export const CardsHome = (props: Props) => {
  const history = useHistory();
  const { isMobile } = useDimensions();
  const getBanner = useClient('BannerFindMany', {
    appendToFragment,
    fetchOnMount: true,
    variables: {
      limit: 100,
      sort: SortFindManyBannerInput.SortAsc,
      filter: {
        validityEnum: ValidityEnum.Current,
        status: EnumBannerStatus.Published
      }
    }
  });

  const data = React.useMemo(() => {
    if (!getBanner?.result?.length) return undefined;
    const filtered = getBanner.result.filter((item) => item?.position);
    return {
      cardsLarge: filterByPosition(
        filtered,
        TypeCard.LARGE.toString(),
        history,
        props.onRegisterBannerClick
      ),
      stories: filterByPosition(
        filtered,
        TypeCard.STORIES.toString(),
        history,
        props.onRegisterBannerClick
      ),
      cardsMedium1: filterByPosition(
        filtered,
        TypeCard.MEDIUM1.toString(),
        history,
        props.onRegisterBannerClick
      ),
      cardsMedium2: filterByPosition(
        filtered,
        TypeCard.MEDIUM2.toString(),
        history,
        props.onRegisterBannerClick
      ),
      cardsSmall: filterByPosition(
        filtered,
        TypeCard.SMALL.toString(),
        history,
        props.onRegisterBannerClick
      ),
      cardsSmall2: filterByPosition(
        filtered,
        TypeCard.SMALL2.toString(),
        history,
        props.onRegisterBannerClick
      ),
      bannerFooter: filterByPosition(
        filtered,
        TypeCard.BANNER_FOOTER.toString(),
        history,
        props.onRegisterBannerClick
      )
    };
  }, [getBanner?.result]);

  if (getBanner.loading) {
    return <LoadingIndicator small />;
  }

  if (
    !getBanner.loading &&
    !data?.stories?.length &&
    !data?.cardsLarge?.length &&
    !data?.cardsMedium1?.length &&
    !data?.cardsMedium2?.length &&
    !data?.cardsSmall?.length &&
    !data?.cardsSmall2?.length &&
    !data?.bannerFooter?.length
  ) {
    return null;
  }

  return (
    <>
      {Boolean(data?.stories?.length) ? (
        <CarouselStories items={data?.stories || []} />
      ) : (
        <CardsWrapper>
          {Boolean(data?.cardsLarge?.length) && (
            <Cards
              plataform="corp"
              type={TypeCard.LARGE}
              items={data?.cardsLarge || []}
              backgroundColor="#fff"
            />
          )}
          {Boolean(data?.cardsMedium1?.length) && (
            <Cards
              plataform="corp"
              type={TypeCard.MEDIUM1}
              items={data?.cardsMedium1 || []}
            />
          )}
          {Boolean(data?.cardsMedium2?.length) && (
            <Cards
              plataform="corp"
              type={TypeCard.MEDIUM2}
              items={data?.cardsMedium2 || []}
              backgroundColor="#fff"
            />
          )}
          <MiniCardsWrapper>
            {Boolean(data?.cardsSmall?.length) && (
              <Cards
                plataform="corp"
                type={TypeCard.SMALL}
                items={data?.cardsSmall || []}
                backgroundColor="#fff"
              />
            )}
            {Boolean(data?.cardsSmall2?.length) && (
              <Cards
                plataform="corp"
                type={TypeCard.SMALL}
                items={data?.cardsSmall2 || []}
                backgroundColor="#fff"
              />
            )}
          </MiniCardsWrapper>
          {Boolean(data?.bannerFooter?.length) && (
            <img
              className="banner-footer"
              src={
                isMobile && data?.bannerFooter?.length
                  ? data.bannerFooter[0].imgMobile
                  : data?.bannerFooter[0].imgDesk
              }
              alt="banner footer"
              style={{ cursor: 'pointer' }}
              onClick={() => data?.bannerFooter[0]?.functionButton()}
            />
          )}
        </CardsWrapper>
      )}
    </>
  );
};
